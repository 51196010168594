import { Component, OnDestroy, ViewChild } from '@angular/core';
import { DealModel } from '../../models/deal.model';
import { DealService } from '../../services/deal.service';
import { finalize, Observable, Subject, take, tap  } from 'rxjs';
import { Style } from '../../modules/gep-controls/models/style';
import { Icon } from '../../modules/gep-controls/models/icon';
import { ButtonSize } from '../../modules/gep-controls/models/button-size';
import { ModalWithData } from '../../models/modal-with-data.model';
import { DealSearchService } from '../../modules/deal-search/services/deal-search.service';
import { QuantityUnit } from '../../models/quantity-unit.model';
import { ToastService } from '../../services/toast.service';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { ColumnDefinitionService } from '../../modules/ag-grid/column-definition.service';
import { ConsignmentsActionsRendererComponent } from '../../modules/ag-grid/renderer/consignments-actions-renderer/consignments-actions-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { AgGridTableComponent } from '../../modules/ag-grid/ag-grid-table/ag-grid-table.component';
import { YearFilterComponent } from '../../modules/ag-grid/filter/year-filter/year-filter.component';
import { getAllPages } from './delivery-consignments-page.functions';

@Component({
  selector: 'gep-delivery-consignments-page',
  templateUrl: './delivery-consignments-page.component.html',
  styleUrls: ['./delivery-consignments-page.component.scss'],
})
export class DeliveryConsignmentsPageComponent implements OnDestroy {
  confirmMultipleModal: ModalWithData = new ModalWithData();

  @ViewChild('agGridTable')
  agGridTable!: AgGridTableComponent;

  protected readonly Style = Style;
  protected readonly Icon = Icon;
  protected readonly ButtonSize = ButtonSize;
  protected readonly QuantityUnit = QuantityUnit;

  protected isSubmitting: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    private dealService: DealService,
    private dealSearchService: DealSearchService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private columnDefinitions: ColumnDefinitionService
  ) {}

  readonly consignmentsData$: Observable<DealModel[]> = getAllPages((...args) => this.dealSearchService.getConsignments(...args));

  colDef: ColDef<DealModel>[] = [
    this.columnDefinitions.dealIdColumn(),
    this.columnDefinitions.dealTypeColumn(),
    {
      field: 'year',
      filter: YearFilterComponent,
    },
    this.columnDefinitions.customerColumn(),
    this.columnDefinitions.counterpartCompanyColumn(),
    this.columnDefinitions.quantityInMWhColumn(),
    this.columnDefinitions.quantityInTonnsColumn(),
    this.columnDefinitions.productKeyColumn(),
    this.columnDefinitions.priceColumn(true),
    this.columnDefinitions.closedDateColumn(true),
    this.columnDefinitions.redistributorColumn(true),
    this.columnDefinitions.vgColumn(true),
    this.columnDefinitions.regionColumn(true),
    this.columnDefinitions.energySourceColumn(true),
    this.columnDefinitions.systemAgeColumn(true),
    this.columnDefinitions.commentColumn(),
    this.columnDefinitions.statementCancellationColumn(),
    this.columnDefinitions.idColumn(),
    {
      headerName: this.translateService.instant('quantity_delivery_confirmed'),
      cellRenderer: ConsignmentsActionsRendererComponent,
      width: 350,
      sortable: false,
      filter: null,
    },
  ];

  gridApi?: GridApi;
  selectedYear: string = '';

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.setFilterModel({
      year: { selectedYear: new Date().getFullYear() },
    });
    params.api.onFilterChanged();
  }

  saveCheckBatched() {
    this.isSubmitting = true;
    const nodes = this.agGridTable.api.getRenderedNodes();
    let data: DealModel[] = nodes
      .map(({ data }) => data)
      .filter((deal: DealModel) => deal.quantity_delivery_confirmed);
    const dataToSend = data.map((deal: DealModel) => ({
      doc_id: deal.id!,
      quantity_delivery_confirmed: deal.quantity_delivery_confirmed,
      quantity_delivery_date: deal.quantity_delivery_date?.toISOString(),
      quantity_delivery_subaccount: deal.quantity_delivery_subaccount,
      redistributor: deal.redistributor,
    }));

    this.dealService
      .updateDealConsignmentBatched(dataToSend)
      .pipe(
        take(1),
        tap(_ => {
          for (const deal of data) {
            this.agGridTable.api.applyTransaction({ remove: [deal] });
          }
          this.toastService.showSavedToast();
        }),
        finalize(() => {
          this.isSubmitting = false;
          this.confirmMultipleModal.close();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

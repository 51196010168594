import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { DealsPageComponent } from './pages/deals-page/deals-page.component';
import { DeliveryConsignmentsPageComponent } from './pages/delivery-consignments-page/delivery-consignments-page.component';
import { DeliveryInvalidationsPageComponent } from './pages/delivery-invalidations-page/delivery-invalidations-page.component';
import { OffersPageComponent } from './pages/offers-page/offers-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { PsiImportPageComponent } from './pages/psi-import-page/psi-import-page.component';
import { MemphisImportPageComponent } from './pages/memphis-import-page/memphis-import-page.component';
import { CreateOfferPageComponent } from './pages/create-offer-page/create-offer-page.component';
import { DeliveryInvalidationsOverviewPageComponent } from './pages/delivery-invalidations-overview-page/delivery-invalidations-overview-page.component';
import { AdminGuard } from './guards/admin.guard';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { EditEnquiryComponent } from './modules/enquiry/components/edit-enquiry/edit-enquiry.component';
import { CreateEnquiryComponent } from './modules/enquiry/components/create-enquiry/create-enquiry.component';
import { HasNoRoleGuard } from './guards/has-no-role.guard';
import { CounterpartGuard } from './guards/counterpart.guard';
import { SaleGuard } from './guards/sale.guard';
import { GreendeskGuard } from './guards/greendesk.guard';
import { EditDealPageComponent } from './modules/admin/pages/edit-deal-page/edit-deal-page.component';
import { GeneralSettingsComponent } from './modules/settings/components/general-settings/general-settings.component';
import { ProductConfigurationComponent } from './modules/settings/components/product-configuration/product-configuration.component';
import { EnquiryTemplatesComponent } from './modules/settings/components/enquiry-templates/enquiry-templates.component';
import { TranslationLoaderGuard } from './guards/translation-loader.guard';
import { MyDealsPageComponent } from './pages/my-deals-page/my-deals-page.component';
import { featureFlagGuard } from './feature-flags/feature-flag.guard';
import { CounterpartUsersComponent } from './modules/settings/components/counterpart-users/counterpart-users.component';
import { DealsDetailsPageComponent } from './pages/deals-details-page/deals-details-page.component';

const routes: Routes = [
  {
    canActivate: [MsalGuard],
    path: '',
    canActivateChild: [
      MsalGuard,
      HasNoRoleGuard,
      CounterpartGuard,
      SaleGuard,
      GreendeskGuard,
      TranslationLoaderGuard,
    ],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
      },
      {
        path: 'deals',
        component: DealsPageComponent,
      },
      {
        path: 'enquiry',
        component: CreateEnquiryComponent,
      },
      {
        path: 'enquiry/:dealId',
        component: EditEnquiryComponent,
      },
      {
        path: 'deliveries',
        loadChildren: () =>
          import('./modules/u20-deliveries/u20-deliveries.module').then(
            ({ U20DeliveriesModule }) => U20DeliveriesModule
          ),
      },
      {
        path: 'admin',
        canActivateChild: [AdminGuard],
        children: [
          {
            path: 'edit-deal',
            pathMatch: 'full',
            component: EditDealPageComponent,
          },
          {
            path: 'edit-deal/:id',
            pathMatch: 'full',
            component: EditDealPageComponent,
          },
        ],
      },
      {
        canActivateChild: [AdminGuard],
        canMatch: [featureFlagGuard('.appconfig.featureflag/settings-page')],
        path: 'settings',
        children: [
          {
            path: 'general',
            component: GeneralSettingsComponent,
          },
          {
            path: 'product-configuration',
            component: ProductConfigurationComponent,
          },
          {
            path: 'enquiry-defaults',
            component: EnquiryTemplatesComponent,
          },
          {
            path: 'counterpart-users',
            component: CounterpartUsersComponent,
          },
        ],
      },
      {
        path: 'offers',
        component: OffersPageComponent,
      },
      {
        path: 'consignments',
        component: DeliveryConsignmentsPageComponent,
      },
      {
        path: 'invalidations',
        component: DeliveryInvalidationsPageComponent,
      },
      {
        path: 'invalidations-overview',
        component: DeliveryInvalidationsOverviewPageComponent,
      },
      {
        path: 'psi-import',
        component: PsiImportPageComponent,
      },
      {
        path: 'memphis-import',
        component: MemphisImportPageComponent,
      },
      {
        path: 'submit-offer',
        component: CreateOfferPageComponent,
      },
      {
        path: 'my-deals',
        canMatch: [featureFlagGuard('.appconfig.featureflag/my-deals')],
        component: MyDealsPageComponent,
      },
      { path: 'deal-details/:id', component: DealsDetailsPageComponent },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'error/404',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { QuantityRendererComponent } from './renderer/quantity-renderer/quantity-renderer.component';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, IDateFilterParams } from 'ag-grid-community';
import { Injectable } from '@angular/core';
import { PriceRendererComponent } from './renderer/price-renderer/price-renderer.component';
import { DateRendererComponent } from './renderer/date-renderer/date-renderer.component';
import { CheckboxRendererComponent } from './renderer/checkbox-renderer/checkbox-renderer.component';
import { ProductStatusRendererComponent } from './renderer/product-status-renderer/product-status-renderer.component';

@Injectable({
  providedIn: 'root',
})
export class ColumnDefinitionService {
  constructor(private translateService: TranslateService) {}

  public quantityInMWhColumn(hide: boolean = false): ColDef {
    return {
      field: 'quantityInMWh',
      headerName: this.translateService.instant('quantityInMWh'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      enableValue: true,
      cellRenderer: QuantityRendererComponent,
      type: 'rightAligned',
      hide: hide,
      width: 200,
    };
  }

  public quantityInTonnsColumn(hide: boolean = false): ColDef {
    return {
      field: 'quantityInTonns',
      headerName: this.translateService.instant('quantityInTonns'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      enableValue: true,
      cellRenderer: QuantityRendererComponent,
      cellRendererParams: {
        unit: 'T',
      },
      type: 'rightAligned',
      hide: hide,
      width: 210,
    };
  }

  public yearColumn(hide: boolean = false, width: number = 100): ColDef {
    return {
      field: 'year',
      headerName: this.translateService.instant('year'),
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      width: width,
      hide: hide,
    };
  }

  public priceColumn(hide: boolean = false, width: number = 120): ColDef {
    return {
      field: 'price',
      headerName: this.translateService.instant('price'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'avg',
      enableValue: true,
      cellRenderer: PriceRendererComponent,
      width: width,
      hide: hide,
    };
  }

  public counterpartCompanyColumn(hide: boolean = false): ColDef {
    return {
      field: 'counterpart_company',
      headerName: this.translateService.instant('counterpart_company'),
      hide: hide,
      filter: 'agSetColumnFilter',
    };
  }

  public commentColumn(hide: boolean = false, width: number = 250): ColDef {
    return {
      field: 'comment',
      headerName: this.translateService.instant('comment'),
      width: width,
      hide: hide,
    };
  }

  public closedDateColumn(hide: boolean = false, width: number = 200): ColDef {
    return {
      field: 'closed_date',
      headerName: this.translateService.instant('closed_date'),
      filter: 'agDateColumnFilter',
      filterParams: {
        closeOnApply: true,
        comparator: this.dateComparator,
      } as IDateFilterParams,
      cellRenderer: DateRendererComponent,
      width: width,
      hide: hide,
    };
  }

  public productKeyColumn(hide: boolean = false, width: number = 200): ColDef {
    return {
      field: 'product_key',
      headerName: this.translateService.instant('product_key'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public energySourceColumn(
    hide: boolean = false,
    width: number = 175
  ): ColDef {
    return {
      field: 'energy_source',
      headerName: this.translateService.instant('energy_source'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public regionColumn(hide: boolean = false, width: number = 175): ColDef {
    return {
      field: 'region',
      headerName: this.translateService.instant('region'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public systemAgeColumn(hide: boolean = false, width: number = 175): ColDef {
    return {
      field: 'system_age',
      headerName: this.translateService.instant('system_age'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public statusColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'status',
      headerName: this.translateService.instant('status'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
      cellRenderer: ProductStatusRendererComponent,
    };
  }

  public dealTypeColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'deal_type',
      headerName: this.translateService.instant('deal_type'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public bindingPeriodStartColumn(
    hide: boolean = false,
    width: number = 200
  ): ColDef {
    return {
      field: 'binding_period_start',
      headerName: this.translateService.instant('binding_period_start'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: {
        closeOnApply: true,
        comparator: this.dateComparator,
      } as IDateFilterParams,
      width: width,
      hide: hide,
    };
  }

  public bindingPeriodEndColumn(
    hide: boolean = false,
    width: number = 200
  ): ColDef {
    return {
      field: 'binding_period_end',
      headerName: this.translateService.instant('binding_period_end'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: {
        closeOnApply: true,
        comparator: this.dateComparator,
      } as IDateFilterParams,
      width: width,
      hide: hide,
    };
  }

  public quantityDeliveryConfirmedColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'quantity_delivery_confirmed',
      headerName: this.translateService.instant('quantity_delivery_confirmed'),
      filter: 'agSetColumnFilter',
      cellRenderer: CheckboxRendererComponent,
      hide: hide,
      width: width,
    };
  }

  public quantityDeliveryDateColumn(
    hide: boolean = false,
    width: number = 200
  ): ColDef {
    return {
      field: 'quantity_delivery_date',
      headerName: this.translateService.instant('quantity_delivery_date'),
      cellRenderer: DateRendererComponent,
      filter: 'agDateColumnFilter',
      filterParams: {
        closeOnApply: true,
        comparator: this.dateComparator,
      } as IDateFilterParams,
      width: width,
      hide: hide,
    };
  }

  public statementCancellationColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'statement_cancellation',
      headerName: this.translateService.instant('statement_cancellation'),
      filter: 'agSetColumnFilter',
      cellRenderer: CheckboxRendererComponent,
      hide: hide,
      width: width,
    };
  }

  public idColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'id',
      headerName: this.translateService.instant('id'),
      hide: hide,
      width: width,
    };
  }

  public contactPersonColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'contact_person',
      headerName: this.translateService.instant('contact_person'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public customerColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'customer',
      headerName: this.translateService.instant('customer'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public dealIdColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'deal_id',
      headerName: this.translateService.instant('deal_id'),
      hide: hide,
      width: width,
    };
  }

  public vgColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'vg',
      headerName: this.translateService.instant('vg'),
      filter: 'agSetColumnFilter',
      hide: hide,
      width: width,
    };
  }

  public redistributorColumn(
    hide: boolean = false,
    width: number | undefined = undefined
  ): ColDef {
    return {
      field: 'redistributor',
      headerName: this.translateService.instant('redistributor'),
      filter: 'agSetColumnFilter',
      cellRenderer: CheckboxRendererComponent,
      hide: hide,
      width: width,
    };
  }

  protected dateComparator(
    filterLocalDateAtMidnight: Date,
    cellValue: string
  ): number {
    const date = new Date(cellValue);

    const filterDate = new Date(
      filterLocalDateAtMidnight.getFullYear(),
      filterLocalDateAtMidnight.getMonth(),
      filterLocalDateAtMidnight.getDate()
    );

    const cellDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    if (cellDate < filterDate) {
      return -1;
    } else if (cellDate > filterDate) {
      return 1;
    } else {
      return 0;
    }
  }
}

import { Observable, concat, defer, map, mergeMap, mergeScan, of } from "rxjs";
import { DealModel } from "../../models/deal.model";
import { PageModel } from "../../models/page.model";
import { AcsSearchParams } from "../../models/acs-search-params.model";

export function fetchPage(
  callbackFn: (acsSearchParams: AcsSearchParams) => Observable<[PageModel<DealModel>]>,
  page = 0,
  page_size = 500
): Observable<{value: DealModel[], nextPage: number}> {
  return callbackFn({
    page,
    page_size,
    search: '',
    sort: undefined,
    version: 1,
    searchMode: 'any',
    queryType: 'full',
  }).pipe(
    //OperatorFunction<[PageModel<DealModel>]
    map(([{ value, page, total_pages }]) => ({
      value,
      nextPage: page < total_pages - 1 ? page + 1 : 0,
    }))
  );
}

/**
 * Takes a request function and makes multiple calls until all pages are received
 * @param callbackFn the function to be called (ex. () => this.http.post('...'))
 * @param page starting page, default is 0
 * @returns An observable stream with accumulating results
 */
export function getAllPages(
  callbackFn: (acsSearchParams: AcsSearchParams) => Observable<[PageModel<DealModel>]>,
  page: number = 0
): Observable<DealModel[]> {
  return defer(() => fetchPage(callbackFn, page)).pipe(
    mergeMap(({ value, nextPage }) =>
      nextPage ? concat(of(value), getAllPages(callbackFn, nextPage)) : of(value)
    ),
    mergeScan((acc, values) => of([...acc, ...values]), [] as DealModel[])
  );
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProductConfigModel } from '../models/product-config.model';

export const REGIONS = [
  'keine Spezifikation / any AIB',
  'any AIB grid connected (no Iceland no Cyprus)',
  'DACH (Germany Austria Switzerland)',
  'Germany',
  'Bayern',
  'Eifel',
  'Austria',
  'Belgium - Federal',
  'Belgium - Brussels',
  'Belgium - Flanders',
  'Belgium - Wallonia',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Greece',
  'Hungary',
  'Italy',
  'Iceland',
  'Ireland',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Netherlands',
  'Norway',
  'Portugal',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
];

export const SYSTEM_AGES = [
  'keine Spezifikation',
  'jünger 20 Jahre',
  'jünger 15 Jahre',
  'jünger 6 Jahre',
];

export const ENERGY_SOURCES = [
  'keine Spezifikation',
  'PV',
  'Wind',
  'Wasser',
  'Geothermie',
  'Biomasse (gültig nach Biomasseverordnung)',
  'big hydro',
  'small hydro',
  'hydro - run of river',
  'Solar',
  'Renewables excluding Biomass',
  'excluded: Hydro / Biomass ; accepted: Wind / Solar / Geothermal',
  'excluded: Wind / Biomass ; accepted: Hydro / Solar / Geothermal',
  'excluded: Geothermal / Biomass ; accepted: Hydro / Wind / Solar',
  'excluded: Solar / Biomass ; accepted: Hydro / Wind / Geothermal',
];

export const SEGMENTS = [
  '',
  'SME - S1 kl. Gewerbek. SLP (S <30; G <80)',
  'SME - S2 gr. Gewerbek. SLP (S >30; G >80)',
  'SME - S4 mi. Geschäftsk. RLM (S <1,0 ; G <5)',
  'SME - S6 Kommunale Betreuung',
  'SME - S7 Rahmenvertragskunden',
  'SME - S8 Wohnungswirtschaften',
  'SME - S9 vertriebliche Einspeiser',
  'PK - (Allgemeinstrom)',
  'PK - (Heizstrom gemeinsame Messung)',
  'PK - (Heizstrom getrennte Messung)',
  'Greendesk',
  'GK - Geschäftskunden',
];

export const VGS = ['EDG', 'EWI', 'Eprimo', 'EPS', 'OIE', 'E.ON AT'];

@Injectable()
export class ProductService {
  constructor(private readonly http: HttpClient) {}

  getProductConfigurations$(): Observable<ProductConfigModel[]> {
    return this.http.get<ProductConfigModel[]>(
      `${environment.API}v1/config/products`
    );
  }

  getVgs$(): Observable<string[]> {
    return of(VGS);
  }

  getSegments$(): Observable<string[]> {
    return of(SEGMENTS);
  }

  getRegions$(): Observable<string[]> {
    return of(REGIONS);
  }

  getSystemAges$(): Observable<string[]> {
    return of(SYSTEM_AGES);
  }

  getEnergySources$(): Observable<string[]> {
    return of(ENERGY_SOURCES);
  }
}

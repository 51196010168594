import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components/components.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DealsPageComponent } from './deals-page/deals-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeliveryConsignmentsPageComponent } from './delivery-consignments-page/delivery-consignments-page.component';
import { DeliveryInvalidationsPageComponent } from './delivery-invalidations-page/delivery-invalidations-page.component';
import { OffersPageComponent } from './offers-page/offers-page.component';
import { PsiImportPageComponent } from './psi-import-page/psi-import-page.component';
import { MemphisImportPageComponent } from './memphis-import-page/memphis-import-page.component';
import { CreateOfferPageComponent } from './create-offer-page/create-offer-page.component';
import { PipesModule } from '../pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ManualOfferComponent } from './offers-page/manual-offer.component';
import { GepControlsModule } from '../modules/gep-controls/gep-controls.module';
import { UbaHeaderModule } from '../modules/uba-header/uba-header.module';
import { DeliveryInvalidationsOverviewPageComponent } from './delivery-invalidations-overview-page/delivery-invalidations-overview-page.component';
import { CommonsModule } from '../modules/commons/commons.module';
import { GepFormsModule } from '../modules/gep-forms/gep-forms.module';
import { EonUiComponentsAngularModule } from '@eon-ui/eon-ui-components-angular';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SettingsModule } from '../modules/settings/settings.module';
import { AdminModule } from '../modules/admin/admin.module';
import { MyDealsPageComponent } from './my-deals-page/my-deals-page.component';
import { AgGridModule } from '../modules/ag-grid/ag-grid.module';
import { DealsDetailsPageComponent } from './deals-details-page/deals-details-page.component';
import { DealsModule } from '../modules/deals/deals.module';
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    DashboardPageComponent,
    DealsPageComponent,
    OffersPageComponent,
    DeliveryConsignmentsPageComponent,
    DeliveryInvalidationsPageComponent,
    PsiImportPageComponent,
    MemphisImportPageComponent,
    CreateOfferPageComponent,
    ManualOfferComponent,
    DeliveryInvalidationsOverviewPageComponent,
    ErrorPageComponent,
    MyDealsPageComponent,
    DealsDetailsPageComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    NgbTooltipModule,
    GepControlsModule,
    UbaHeaderModule,
    CommonsModule,
    GepFormsModule,
    AgGridModule,
    EonUiComponentsAngularModule,
    EonUiComponentsAngularModule,
    SettingsModule,
    AdminModule,
    DealsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{ provide: LOCALE_ID, useValue: 'de' }],
})
export class PagesModule {}

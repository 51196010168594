<ag-grid-angular
  [rowData]="rowData$ | async"
  [columnDefs]="colDefs"
  [rowSelection]="rowSelection"
  [components]="components"
  [defaultColDef] = "defaultColDefConfig"
  [gridOptions]="_gridOptions"
  [sideBar]="sideBar"
  [rowGroupPanelShow]="showRowGroupPanel"
  [pagination]="pagination"
  [paginationPageSizeSelector]="paginationPageSizeSelector"
  [paginationPageSize]="defaultPageSize"
  [localeText]="localeText"
  (gridReady)="onGridReady($event)"
  class="ag-theme-quartz"
  style="height: 70vh">
</ag-grid-angular>

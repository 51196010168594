<gep-uba-tab-header></gep-uba-tab-header>

<main class="bottomed">
  <div class="save-all-received mb-3">
    <gep-button
      *ngIf="!isSubmitting"
      [label]="'consignments.saveAllReceived' | translate"
      [size]="ButtonSize.Small"
      (click)="confirmMultipleModal.show()"
    ></gep-button>
    <gep-loading-spinner [visible]="isSubmitting" [size]="ButtonSize.Small"></gep-loading-spinner>
  </div>



  <gep-ag-grid-table
    [rowData$]="consignmentsData$"
    [colDefs]="colDef"
    (gridReady)="onGridReady($event)"
    #agGridTable
  ></gep-ag-grid-table>

  <gep-modal
    [visible]="confirmMultipleModal.visible"
    [headline]="'consignments.acceptConsignment' | translate"
    [description]="'consignments.questionForConsignmentMultiple' | translate"
    [positiveButtonStyle]="Style.Highlight"
    [closeButtonText]="'no' | translate"
    [positiveButtonText]="'yes' | translate"
    (positiveButtonClicked)="saveCheckBatched()"
    (closedButtonClicked)="confirmMultipleModal.close()"
    [showClosingX]="true"
    modalId="show-save-all-consignment-confirmation"
  ></gep-modal>
</main>
